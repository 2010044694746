import request from "@/plugins/axios";

/** S 商城列表 **/
// 商城列表
export const apiShopLists = (params: any) =>
  request.get("/shop.shop/lists", { params });

// 添加商城
export const apiShopAdd = (params: any) =>
  request.post("/shop.shop/add", params);

// 商城详情
export const apiShopDetail = (params: any) =>
  request.get("/shop.shop/detail", { params });

// 编辑商城
export const apiShopEdit = (params: any) =>
  request.post("/shop.shop/edit", params);

// 删除商城
export const apiShopDelete = (params: any) =>
  request.post("/shop.shop/delete", params);

// 修改超级管理员
export const apiShopChangeSuperAdmin = (params: any) =>
  request.post("/shop.shop/changeSuperAdmin", params);

// 修改商城状态
export const apiShopSwitchStatus = (params: any) =>
  request.post("/shop.shop/switchStatus", params);

// 历史套餐
export const apisetMealLogLists = (params: any) =>
  request.get("/shop.shop/setMealLogLists", { params });

// 修改商城备注
export const apiChangeRemark = (params: any) =>
  request.post("/shop.shop/changeRemark", params);
/** E 商城列表 **/

/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) =>
  request.get("/shop.set_meal/lists", { params });

// 添加套餐
export const apiSetMealAdd = (params: any) =>
  request.post("/shop.set_meal/add", params);

// 套餐详情
export const apiSetMealDetail = (params: any) =>
  request.get("/shop.set_meal/detail", { params });

// 编辑套餐
export const apiSetMealEdit = (params: any) =>
  request.post("/shop.set_meal/edit", params);

// 删除套餐
export const apiSetMealDelete = (params: any) =>
  request.post("/shop.set_meal/del", params);

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) =>
  request.post("/shop.set_meal/status", params);

// 获取套餐限制的菜单列表
export const apiMenuListForObtainingPackageRestrictions = (params: any) =>
  request.get("/shop.set_meal/getMenu", params);
/** E 套餐列表 **/
